export type Environment = "development" | "production" | "staging" | "preview";

function required(envVar: string, defaultValue?: string) {
  let res = typeof process != "undefined" && process.env[envVar];

  // see getEnvVarScriptTag
  if (!res && typeof window != "undefined") {
    res = (window as any).env?.[envVar];
  }

  if (!res) {
    if (
      defaultValue != undefined &&
      (envVar == "NODE_ENV" || getEnvironment() != "production")
    ) {
      res = defaultValue;
    } else {
      throw new Error(`Missing env var ${envVar}`);
    }
  }

  return res;
}

const environment = required("NODE_ENV", "development") as Environment;

export function getEnvironment(): Environment {
  return environment;
}

/** This script tag should be included on each page to transfer the environment variables
 * we need on the client side.
 */
export function getEnvVarScriptTag(
  customVars?: string[],
  getValue?: (envVar: string) => string
) {
  if (typeof window != "undefined") {
    return "";
  }

  // this list of variables will probably be expanded later.
  const clientSideEnvVars = [
    "NODE_ENV",
    "IMGIX_BASE_URL",
    "IMGIX_TOKEN",
    "SENTRY_DSN",
    "GTM_CONTAINER_ID",
  ].concat(customVars || []);

  const env: Record<string, string> = clientSideEnvVars.reduce(
    (env, envVar) => ({
      ...env,
      [envVar]: getValue ? getValue(envVar) : process.env[envVar],
    }),
    {}
  );

  return `<script>window.env = ${JSON.stringify(env)}</script>`;
}

export const getStoryblokToken = () =>
  required("STORYBLOK_TOKEN", "9xu5JN1YLvQ5tgGQSnrR0wtt");

export function getStoryblokVersion(): "draft" | "published" {
  const defaultValue = "draft";

  const res = required("STORYBLOK_VERSION", defaultValue);

  return ["draft", "published"].find((ver) => ver === res)
    ? (res as "draft" | "published")
    : defaultValue;
}

export const getStoryblokEnvId = () =>
  parseInt(required("STORYBLOK_ENV_ID", "158087"));

export const getStoryblokImageUrl = () =>
  `https://a.storyblok.com/f/${getStoryblokEnvId()}`;

export const getCentraCheckoutApiKey = () =>
  required("CENTRA_CHECKOUT_API_KEY");
export const getCentraDomain = () =>
  required("CENTRA_DOMAIN", "xworkwear.centraqa.com");

/* "shared secret" in the external payment plugin */
export const getCentraExternalPaymentSecret = () =>
  required("CENTRA_EXTERNAL_PAYMENT_SECRET");

/* For the Order API; see "System", "Stores", "View" on the store,
 * "Plugins", "Order API" in the Centra UI */
export const getCentraOrderApiKey = (): string =>
  required("CENTRA_ORDER_API_KEY");

/* For the GraphQL API; see "System", "API Tokens" in the Centra UI */
export const getCentraApiToken = () => required("CENTRA_API_TOKEN");

export const getCentraOrderWebhookSecret = () =>
  required("CENTRA_ORDER_WEBHOOK_SECRET");

export const getRedisUrl = () => required("REDIS_URL", "");

export const getImgIxBaseUrl = () =>
  required("IMGIX_BASE_URL", "https://xw.imgix.net/");

export const getImgIxToken = () => required("IMGIX_TOKEN");

export const getImgIxAzureBaseUrl = () =>
  required("IMGIX_AZURE_BASE_URL", "https://xwuf.imgix.net/");

export const getAzureBlobConnectionString = () =>
  required("AZURE_BLOB_CONNECTION_STRING");

export const getKlarnaApiHost = () =>
  required("KLARNA_API_HOST", "https://api.playground.klarna.com");
export const getKlarnaPassword = () =>
  required("KLARNA_PASSWORD", "ydE30EnkSJpmFtVL");
export const getKlarnaUsername = () =>
  required("KLARNA_USERNAME", "PK40025_350f11dc60ef");

export const getLedyerWithAccountStoreId = () =>
  required("LEDYER_STORE_ID", "421423100");
export const getLedyerWithAccountClientId = () => required("LEDYER_CLIENT_ID");
export const getLedyerWithAccountClientSecret = () =>
  required("LEDYER_CLIENT_SECRET");

export const getLedyerWithoutAccountStoreId = () =>
  required("LEDYER_NOT_LOGGED_IN_STORE_ID", "427278874");
export const getLedyerWithoutAccountClientId = () =>
  required("LEDYER_NOT_LOGGED_IN_CLIENT_ID", "cl_2IUC4vBLtzs6fBtPb8TuvRIhrGL");
export const getLedyerWithoutAccountClientSecret = () =>
  required(
    "LEDYER_NOT_LOGGED_IN_CLIENT_SECRET",
    "BamsTAGxDtGpsLRULprTyIvOcjNxw1"
  );

export const getLedyerAccessClientId = () =>
  required("LEDYER_ACCESS_CLIENT_ID");
export const getLedyerAccessClientSecret = () =>
  required("LEDYER_ACCESS_CLIENT_SECRET");
export const getLedyerDomain = () =>
  required("LEDYER_DOMAIN", "sandbox.ledyer.com");
export const getJWTSecret = () =>
  required("JWT_SECRET", "replacemeinproduction");

export const getYotpoAppKey = () => required("YOTPO_APP_KEY");
export const getYotpoSecret = () => required("YOTPO_SECRET");
export const getYotpoCdnEndpoint = () =>
  required("YOTPO_CDN_URL", `https://api-cdn.yotpo.com`);
export const getYotpoCoreEndpoint = () =>
  required("YOTPO_CORE_URL", `https://api.yotpo.com/core/v3/stores`);

export const getKlaviyoBaseUrl = () => required("KLAVIYO_BASE_URL", "https://a.klaviyo.com/api");
export const getKlaviyoApiVersion = () => required("KLAVIYO_API_VERSION", "2024-07-15");
export const getKlaviyoApiKey = () => required("KLAVIYO_API_KEY", "pk_96f92aea60d4c86130bf763f175f07307d");

export const doSendNotifications = () => required("SEND_SMS", "") === "true";

export const getRuleApiKey = () => required("RULE_API_KEY");

export const getRuleSMSApiKey = () => required("RULE_SMS_API_KEY");

export const getSentryDsn = () => required("SENTRY_DSN", "");

export const getLogtailSourceToken = () => required("LOGTAIL_SOURCE_TOKEN", "");

export const getGtmContainerId = () => required("GTM_CONTAINER_ID", "");

export const getXWEndpointToken = () => required("XW_ENDPOINT_TOKEN", "");

export const getMailgunApiKey = () => required("MAILGUN_API_KEY");
