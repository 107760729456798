import {
  getEnvironment,
  getGtmContainerId,
  getSentryDsn,
} from "$utils/env/env";
import type { ErrorResponseMessage } from "@crown/data";
import { handlers as ga4, init as ga4Init } from "@crown/ga4";
import "@crown/jewels/client";
import { init } from "@crown/jewels/client";
import { LoggableError, addErrorListener } from "@crown/logging";
import { logToConsole } from "@crown/logging/src/log-to-console";
import { addHandlers } from "@crown/tracking";
import { registerImageScalers } from "./image-scalers";
import "./styles/fonts.css";

const dsn = getSentryDsn();

import("@sentry/browser").then((SentryBrowser) => {
  SentryBrowser.init({
    dsn,
    environment: getEnvironment(),
    tracesSampleRate: 0.1,
    ignoreErrors: ["Failed to fetch"],
  });

  addErrorListener(logErrorClient);

  function logErrorClient(error: string | LoggableError) {
    if (typeof error == "string") {
      SentryBrowser.captureMessage(error);
    } else if (isWorthLogging(error)) {
      const code = (error as ErrorResponseMessage).code;

      SentryBrowser.withScope((scope: any) => {
        if (code) {
          // group errors together based on their code
          scope.setFingerprint([code]);
        }
        SentryBrowser.captureException(error);
      });
    }
  }
});

addErrorListener(logToConsole);

const ignoreCodes = ["notLoggedIn"];

export function isWorthLogging(error: ErrorResponseMessage) {
  const message = error.message || "";

  const isNetworkProblem =
    ignoreCodes.includes(error.code || "") ||
    /* chrome */
    message.includes("Failed to fetch") ||
    message.includes("getaddrinfo ENOTFOUND") ||
    /* firefox */
    message.includes("NetworkError") ||
    /* safari */
    message.includes("nedkopplad") ||
    message == "Not found" ||
    /* mobile safari */
    message.includes("Load failed") ||
    message.includes("avbruten");

  return !isNetworkProblem;
}

const containerId = getGtmContainerId();

if (containerId && getEnvironment() !== "development") {
  ga4Init({ containerId, debugMode: getEnvironment() == "staging" });
  addHandlers(ga4);
}

registerImageScalers();

init(new Map());
