import { logToConsole } from "./log-to-console";
export { logToConsole } from "./log-to-console";

export type ErrorListener = (
  error: LoggableError | string,
  level: Level,
  context?: Context
) => void;

const listeners: Array<ErrorListener> = [];

export type Level = "error" | "warn" | "info" | "debug";
export type Context = Record<string, string | number | undefined>;

/** An Error that includes more information for better logging, such as a "user message" to display to
 * the end user, a code code that enables catching code to check for certain types of errors or context
 * that allows you to search the logs better.
 */
export interface LoggableError {
  /** An internal error message. */
  message: string;
  /** A message that should be shown to the user. */
  userMessage?: string;
  /** A code for the error type. Used if the client needs to check if a certain type of error occurred */
  code?: string;
  /** An HTTP status code corresponding to the error. */
  status?: number;
  /** Optionally identifies this occurrence; helps finding the error in logs. */
  id?: string;
  /** Put additional variable values in the context to un-clutter the message and make it easier to search for the message in logtail */
  context?: Context;
  stack?: string;
}

export function toLoggableError(
  error: Error | LoggableError | string,
  info: Partial<LoggableError> /* Add a prefix to the error, e.g. "While loading products" */ & {
    messagePrefix?: string;
  }
) {
  let loggableError: LoggableError;

  if (typeof error == "object" && "message" in error) {
    loggableError = error;
  } else {
    loggableError = new Error(error.toString());

    const stackEls = loggableError.stack?.split("\n");
    // remove first line of stack trace which is this function
    stackEls?.splice(1, 1);
    loggableError.stack = stackEls?.join("\n");
  }

  if (info.context && loggableError.context) {
    info.context = { ...info.context, ...loggableError.context };
  }

  try {
    Object.assign(loggableError, info);
  } catch (e: any) {
    warn(`While setting error context: ${e.message}`);
    // you could imagine this happening on a read-only object
  }

  if (info.messagePrefix) {
    const message = `${info.messagePrefix}: ${loggableError.message}`;

    try {
      loggableError.message = message;
    } catch (e) {
      // this can happen if the message property is read-only
      const newError = new Error();

      Object.assign(newError, loggableError);
      newError.message = message;

      loggableError = newError;
    }
  }

  return loggableError;
}

export class UserMessageError extends Error implements LoggableError {
  constructor(
    wrap: Error,
    public userMessage?: string,
    public status?: number,
    public code?: string,
    public context?: Context,
    public id?: string
  ) {
    super(wrap.message);

    this.stack = wrap.stack;
  }
}

export class GroupedError extends Error {
  constructor(message: string, public code: string, keepStack = true) {
    super(message);

    if (!keepStack) {
      this.stack = undefined;
    }
  }
}

export function error(error: LoggableError | string, context?: Context) {
  log(error, "error", context);
}

export function warn(error: LoggableError | string, context?: Context) {
  log(error, "warn", context);
}

export function info(error: LoggableError | string, context?: Context) {
  log(error, "info", context);
}

export function debug(error: LoggableError | string, context?: Context) {
  log(error, "debug", context);
}

export default function log(
  error: LoggableError | string,
  level: Level = "error",
  context?: Context
) {
  if (error == null) {
    error = "null";
  }

  const errorContext = (error as LoggableError).context;

  if (errorContext) {
    context = context ? { ...context, ...errorContext } : errorContext;
  }

  if (listeners.length) {
    listeners.forEach((listener) => listener(error, level, context));
  } else {
    logToConsole(error, level, context);
  }
}

/* Backward compatibility. Also, it can be convenient to distiguish the error variable from the log function */
export const logError = error;

export function addErrorListener(listener: ErrorListener) {
  if (!listeners.includes(listener)) {
    listeners.push(listener);
  }
}

/** Add this as error listener to assign random IDs to errors.
 * These will be logged and should be displayed to the user
 * so it is possible to find an error a user saw in the logs.  */
export const idAssigningErrorListener: ErrorListener = (
  error: LoggableError | string
) => {
  if (typeof error != "string" && error.id == null) {
    error.id = createRandomId();
  }
};

// the entropy isn't particularly high, but if there is an ID collision every once in a while,
// it's not a big deal.
const createRandomId = () => Math.round(Math.random() * 10000000).toString(35);
