import type {
  AddedToCart,
  CategoryPageViewed,
  CheckoutStepOpened,
  CheckoutStepSubmitted,
  PageViewed,
  ProductClicked,
  ProductViewed,
  Purchased,
  RemovedFromCart,
  SearchResultViewed,
} from "@crown/tracking";
import type { Crown } from "@crown/types";

// Note that this must be assignable from the Crown.Product
interface Product {
  sku?: string;
  id: string;
  name: string;
  brand?: { name: string };
  price: Crown.Product.Price;
}

// Note that this must be assignable from the Crown.Cart.Item
interface Item {
  product: Product;
  quantity: number;
  itemId: string;
}

function productToItem(product: Product): Gtag.Item {
  return itemToItem({
    quantity: 1,
    itemId: product.sku || product.id,
    product,
  });
}

function itemToItem(item: Item): Gtag.Item {
  const { product, quantity } = item;

  return {
    item_id: "sku" in product ? (product.sku as string) : product.id,
    item_name: product.name,
    currency: product.price.incVat.currency,
    item_brand: product.brand?.name,
    price: quantity * product.price.incVat.amount,
    quantity,
  };
}

export function addedToCart(event: AddedToCart) {
  window.gtag("event", "add_to_cart", {
    currency: event.item.product.price.incVat.currency,
    value: event.item.product.price.incVat.amount,
    items: [itemToItem(event.item)],
  });
}

export function removedFromCart(event: RemovedFromCart) {
  window.gtag("event", "remove_from_cart", {
    currency: event.item.product.price.incVat.currency,
    value: event.item.product.price.incVat.amount,
    items: [itemToItem(event.item)],
  });
}

export function productViewed(event: ProductViewed): void {
  window.gtag("event", "view_item", {
    currency: event.product.price.incVat.currency,
    value: event.product.price.incVat.amount,
    items: [productToItem(event.product)],
  });
}

export function productClicked(event: ProductClicked): void {
  window.gtag("event", "view_item_list", {
    items: [productToItem(event.product)],
  });
}

export function pageViewed(event: PageViewed): void {
  window.gtag("event", "page_view", {
    page_path: event.url,
    page_title: event.title,
  });
}

export function categoryPageViewed(event: CategoryPageViewed): void {
  window.gtag("event", "page_view", {
    page_path: event.location,
    page_type: event.pageType,
  });
}

export function searchResultViewed(event: SearchResultViewed): void {
  window.gtag("event", "page_view", {
    page_path: event.location,
    page_type: "Search result",
  });
}

export function checkoutStepOpened(event: CheckoutStepOpened) {
  window.gtag("event", "begin_checkout", {
    currency: event.cart.currency,
    value: event.cart.price,
    items: event.cart.items.map(itemToItem),
  });
}

export function checkoutStepSubmitted(event: CheckoutStepSubmitted) {}

export function purchased(event: Purchased) {
  window.gtag("event", "purchase", {
    transaction_id: event.transactionId,
    affiliation: event.affiliation,
    value: event.cart?.price,
    currency: event.cart?.currency,
    items: event.cart?.items.map(itemToItem),
  });
}
