import type { Context, ErrorListener } from ".";

export const logToConsole: ErrorListener = (error, level, context) => {
  let message =
    typeof error == "string"
      ? error
      : "stack" in error && error.stack != undefined
      ? [error.message, ...error.stack.split("\n").slice(1)].join("\n")
      : error.message
      ? error.message
      : error.toString();

  if (context) {
    message +=
      (message?.includes("\n") ? "\n" : " ") + contextToString(context);
  }

  if (level == "error") {
    console.error(message);
  } else if (level == "warn") {
    console.warn(message);
  } else {
    console.log(message);
  }
};

function contextToString(context: Context) {
  return Object.entries(context)
    .filter(([_key, value]) => value != undefined && value != "")
    .map(([key, value]) => `${key}: ${value}`)
    .join(", ");
}
