declare interface Window {
  gtag: (...args: any[]) => void;
  dataLayer: any[];
}

const win = window as any as Window;

win.dataLayer = win.dataLayer || [];

function gtag(...args: any[]) {
  win.dataLayer.push(arguments);
}

window.gtag = gtag;

export function init({
  containerId,
  debugMode,
}: {
  containerId: string;
  debugMode?: boolean;
}): void {
  const isGtm = containerId.startsWith("GTM-");

  let windowLoaded = false;

  if (typeof window !== "undefined") {
    if (document.readyState === "complete") {
      windowLoaded = true;
    } else {
      window.addEventListener("load", () => {
        windowLoaded = true;
      });
    }
  }

  const inject = () => {
    if (
      typeof location != "undefined" &&
      location.href.includes("nogtm=true")
    ) {
      return;
    }

    setTimeout(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = `https://www.googletagmanager.com/${
        isGtm ? `gtm.js` : `gtag/js`
      }?id=${containerId}`;

      document.head.appendChild(script);

      if (isGtm) {
        win.dataLayer.push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
      } else {
        win.gtag("js", new Date());
        win.gtag("config", containerId, { debug_view: debugMode });
      }
    }, 2000);

    // Clean up event listener
    window.removeEventListener("load", inject);
  };

  if (windowLoaded) {
    inject();
  } else {
    window.addEventListener("load", inject);
  }
}
